<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-card elevation="6" outlined>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{$t('accounting.lang_nav_accounting_datevinterface')}}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  :disabled="stepper === 1 || stepper === 2"
                  :loading="stepOneLoader || stepTwoLoader || stepThreeLoader || stepFourLoader"
                  @click="save"
                  color="primary"
                  dark
                  icon
                  v-on="on"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('generic.lang_save') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  :disabled="stepper == 1"
                  :loading="stepOneLoader || stepTwoLoader || stepThreeLoader || stepFourLoader"
                  @click="previous"
                  color="primary"
                  dark
                  icon
                  v-on="on"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </template>
            <span>{{ this.$t('generic.lang_back') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  @click="reset"
                  color="error"
                  dark
                  icon
                  v-on="on"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('generic.lang_reset') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  :disabled="stepper == 4"
                  :loading="stepOneLoader || stepTwoLoader || stepThreeLoader || stepFourLoader"
                  @click="next"
                  color="primary"
                  dark
                  icon
                  v-on="on"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </template>
            <span>{{ this.$t('generic.lang_next') }}</span>
          </v-tooltip>
        </div>
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <v-progress-linear indeterminate v-if="this.loading"/>
        <v-stepper class="elevation-0" v-model="stepper">
          <v-stepper-header>
            <v-stepper-step :complete="stepper > 1" step="1">{{ $t('accounting.lang_accountAssignment') }}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="stepper > 2" step="2">{{ $t('accounting.lang_contraAccountsAssignment') }}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="stepper > 3" step="3">{{ $t('accounting.lang_costCentreAssignment') }}
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step step="4">{{ $t('accounting.lang_interfaceSettings') }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="pa-0 ma-0">
            <v-stepper-content class="pa-0 ma-0" step="1">
              <v-card
                  class="pa-0 ma-0"
                  elevation="0"
              >
                <div class="pa-3 d-flex">
                  <v-text-field :readonly="stepOneLoader" autofocus dense
                                :label="$t('accounting.lang_accountVoucherSale')" outlined
                                v-model="stepOneData.datevAPISettingsKontoGeldEingangGutscheinVK"/>
                  <v-btn :disabled="loading" :loading="loading" @click="submitStepOne()" class="ma-0 mx-5" icon color="primary">
                      <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </div>
                <v-expansion-panels class="elevation-0 ma-0 pa-0" focusable tile>
                  <v-expansion-panel
                      :key="i"
                      v-for="(item,i) in this.waregroupArr"
                  >
                    <v-expansion-panel-header>
                      <span class="primary--text ">{{ item[1] }}</span>
                      <div class="justify-end align-content-end text-right">
                        <v-btn :disabled="savingGroup" :loading="savingGroup" @click="saveGroup(item[0])" class="ma-0 mr-5" icon color="primary">
                          <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-5">

                      <v-row>
                        <v-col cols="6">
                          <strong>{{$t('accounting.lang_accountIdent')}}</strong>
                        </v-col>

                        <v-col cols="6">
                          <strong class="text-uppercase">{{$t('generic.lang_AccountNumber')}}</strong>
                        </v-col>

                        <v-col :key="t" class="pa-0 ma-0" cols="12" v-for="(tax , t) in taxArr">
                          <v-row align="center" class="ma-0 pa-0" justify="center">
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_salesWith')}} {{ tax[2] }}{{$t('accounting.lang_percentTax')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'turnaround_'+item[0]+'_taxID_'+tax[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['turnaround_'+item[0]+'_taxID_'+tax[0]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_bookingKeyFor')}} {{ tax[2] }}{{$t('accounting.lang_percentTax')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'bookingKey_'+item[0]+'_taxID_'+tax[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['bookingKey_'+item[0]+'_taxID_'+tax[0]]"/>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <v-switch :name="'displaySingleSales_waregroupID_' + item[0]"
                                    :value="1"
                                    :label="$t('accounting.lang_displayIndividualSales')"
                                    v-model="stepOneData['displaySingleSales_waregroupID_' + item[0]]"/>
                        </v-col>


                        <v-col class="pa-0 ma-0" cols="12">
                          <v-row align="center" class="ma-0 pa-0" justify="center">
                            <v-col cols="12" sm="6">
                              {{$t('accounting.costcentre')}} 1
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'kostStelle_1_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['kostStelle_1_waregroupID_'+item[0]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.costcentre')}} 2
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'kostStelle_2_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['kostStelle_2_waregroupID_'+item[0]]"/>
                            </v-col>


                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_overwriteDebitNark')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'overwriteSoll_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['overwriteSoll_waregroupID_'+item[0]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_overwriteCreditMark')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'overwriteHaben_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['overwriteHaben_waregroupID_'+item[0]]"/>
                            </v-col>


                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_inventoryAccount')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'wareneinkaufkonto_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['wareneinkaufkonto_waregroupID_'+item[0]]"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                               {{$t('accounting.lang_goodsReceiptAccount')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'Wareneingangsrechnung_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['Wareneingangsrechnung_waregroupID_'+item[0]]"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_inventoryDifferenceExpenditure')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'InventurdifferenzAufwand_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['InventurdifferenzAufwand_waregroupID_'+item[0]]"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_inventureDiffYield')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'InventurdifferenzErtrag_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['InventurdifferenzErtrag_waregroupID_'+item[0]]"/>
                            </v-col>


                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_costOfSalesAcc')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'wareneinsatzkonto_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['wareneinsatzkonto_waregroupID_'+item[0]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_discountGivenAcc')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'discountkonto_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['discountkonto_waregroupID_'+item[0]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_priceChangeAcc')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :name="'pricechangekonto_waregroupID_'+item[0]" dense
                                  outlined
                                  type="text"
                                  v-model="stepOneData['pricechangekonto_waregroupID_'+item[0]]"/>
                            </v-col>

                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <v-btn :disabled="savingGroup" :loading="savingGroup" @click="saveGroup(item[0])" class="mx-0"
                                 block large color="primary">
                            {{ $t('generic.lang_save') }} {{ item[1] }}
                          </v-btn>
                        </v-col>
                      </v-row>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0 ma-0" step="2">
              <v-card
                  class="pa-0 ma-0"
                  elevation="0"
              >


                <v-expansion-panels class="elevation-0 ma-0 pa-0" focusable>
                  <v-expansion-panel
                      :key="i"
                      class="pa-0 elevation-0 ma-0" v-for="(item,i) in this.cashierIDs"
                  >
                    <v-expansion-panel-header class="primary--text">
                      <span>{{$t('generic.lang_kasse')}} - {{ item[1] }}</span>
                      <div class="justify-end align-content-end text-right">
                        <v-btn :disabled="savingGroup" :loading="savingGroup" @click="saveGroupStep2(item[0])" class="ma-0 mr-5" icon color="primary">
                          <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                      <v-row class="">
                        <v-col cols="6">
                          <strong>{{$t('accounting.lang_balanceAccountIdent')}}</strong>
                        </v-col>

                        <v-col cols="6">
                          <strong>{{$t('accounting.lang_balanceAccountNumber')}}</strong>
                        </v-col>

                        <v-col class="pa-0 ma-0" cols="12"
                        >
                          <v-row align="center" class="ma-0 pa-0" justify="center">
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} {{$t('generic.lang_cash')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_1_cashierID_' +item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_1_cashierID_' +item[1]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} {{$t('generic.lang_ec')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_3_cashierID_' +item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_3_cashierID_' +item[1]]"/>
                            </v-col>
                            <v-col cols="12">
                              <v-switch :name="'einzelECBuchungen_kassenid_' + item[1]"
                                        :value="1"
                                        :label="$t('accounting.lang_CardPaymentAsSingleTrans')"
                                        v-model="stepTwoData['einzelECBuchungen_kassenid_' + item[1]]"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} {{$t('generic.lang_creditCard')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_9_cashierID_' +item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_9_cashierID_' +item[1]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} {{$t('accounting.lang_bill')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_4_cashierID_' +item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_4_cashierID_' +item[1]]"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} {{$t('erp.lang_voucher')}}
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_2_cashierID_' +item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_2_cashierID_' +item[1]]"/>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" sm="6">
                          {{$t('accounting.lang_accountPaymentAssign')}} PayPal
                        </v-col>
                        <v-col cols="12" sm="6">
                          <b-form-input
                              :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :name="'optPayment_11_cashierID_' +item[1]"
                              @focus="showTouchKeyboard"
                              dense
                              outlined
                              type="text"
                              v-model="stepTwoData['optPayment_11_cashierID_' +item[1]]"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          {{$t('accounting.lang_accountPaymentAssign')}} Stripe
                        </v-col>
                        <v-col cols="12" sm="6">
                          <b-form-input
                              :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :name="'optPayment_25_cashierID_' +item[1]"
                              @focus="showTouchKeyboard"
                              dense
                              outlined
                              type="text"
                              v-model="stepTwoData['optPayment_25_cashierID_' +item[1]]"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          {{$t('accounting.lang_accountPaymentAssign')}} {{$t('customers.lang_custCard')}}
                        </v-col>
                        <v-col cols="12" sm="6">
                          <b-form-input
                              :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :name="'optPayment_12_cashierID_' +item[1]"
                              @focus="showTouchKeyboard"
                              dense
                              outlined
                              type="text"
                              v-model="stepTwoData['optPayment_12_cashierID_' +item[1]]"/>
                        </v-col>

                        <v-col v-if="$store.getters['permissions/checkModule'](51)" cols="12" sm="6">
                          {{$t('accounting.lang_accountPaymentAssign')}} V-Office
                        </v-col>
                        <v-col v-if="$store.getters['permissions/checkModule'](51)" cols="12" sm="6">
                          <b-form-input
                              :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :name="'optPayment_104_cashierID_' +item[1]"
                              @focus="showTouchKeyboard"
                              dense
                              outlined
                              type="text"
                              v-model="stepTwoData['optPayment_104_cashierID_' +item[1]]"/>
                        </v-col>

                        <v-col :key="t" class="pa-0 ma-0" cols="12"
                               v-for="(pay , t) in additionalPaymentArray">
                          <v-row align="center" class="ma-0 pa-0" justify="center">
                            <v-col cols="12" sm="6">
                              {{$t('accounting.lang_accountPaymentAssign')}} ({{ pay[1] }})
                            </v-col>
                            <v-col cols="12" sm="6">
                              <b-form-input
                                  :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :name="'optPayment_'+pay[0]+'_cashierID_'+item[1]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  type="text"
                                  v-model="stepTwoData['optPayment_'+pay[0]+'_cashierID_'+item[1]]"/>
                            </v-col>
                          </v-row>
                        </v-col>


                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0 ma-0" step="3">
              <v-card
                  elevation="0"
              >
                <span class="pa-3">{{$t('accounting.lang_overwrittenByAccountCostCentre')}}</span>

                <v-data-table
                    :headers="headers"
                    :items="cashierIDsThree"
                    :items-per-page="100"
                    class="elevation-0"
                    item-key="name"
                >
                  <template v-slot:item.value="{item}">
                    <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  v-model="stepThreeData[item.id][0]"/>
                  </template>
                  <template v-slot:item.value2="{item}">
                    <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  v-model="stepThreeData[item.id][1]"/>
                  </template>

                </v-data-table>

              </v-card>

            </v-stepper-content>

            <v-stepper-content class="pa-0 ma-0" step="4">
              <v-card
                  class="pa-4"
                  elevation="0"
              >
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  autofocus dense :label="$t('accounting.lang_consultantNo')"
                                  outlined v-model="stepFourData.datevAPISettingsBeraterNo"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense :label="$t('accounting.lang_clientNumber')"
                                  outlined v-model="stepFourData.datevAPISettingsMandantNo"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense :label="$t('accounting.lang_beginOffiscalYear')"
                                  outlined
                                  type="year"
                                  v-model="stepFourData.datevAPISettingsBeginBusinessYear"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_accountLength')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsAccLength"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_accountNoProceeds')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsErloesAcc"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_cashBookAutoAccount')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsErloesAccAutoCashBook"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_tipCashbookAccount')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsCashBookTip"/>
                  </v-col>


                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_cashbookSurplusAccount')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsCashbookSurplusAcc"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_cashbookDeficitAccount')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsCashbookDeficitAcc"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_sapDocType')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsSAPBelegart"/>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="12" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  dense
                                  :label="$t('accounting.lang_sapAccountArea')"
                                  outlined
                                  v-model="stepFourData.datevAPISettingsSAPBuchungskreis"/>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-switch name="SAPMahnbereichAktiv"
                              :value="1"
                              :label="$t('accounting.lang_dunningAreaSap')"
                              v-model="stepFourData.SAPMahnbereichAktiv"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-switch name="disableCommitFlag"
                              readonly
                              v-model="this.stepFourData.disableCommitFlag"
                              @click="onCommitFlagClick()"
                              :label="$t('accounting.lang_disableCommintFlag')"/>
                  </v-col>
                </v-row>
              </v-card>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Template from "../../settings/customerdisplay/Template";
import Birthdaypicker from "../../common/birthdaypicker";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "DatevConfigComponent",
  components: {Birthdaypicker, Template},
  mixins: [mixin],
  data: () => {
    return {
      stepper: 1,
      stepOne: {},
      loading: false,
      savingGroup: false,
      waregroupArr: null,
      taxArr: null,
      stepOneData: {},
      stepTwoData: {},
      stepThreeData: {},
      stepFourData: {},
      stepThree: {},
      stepFour: {},
      cashierIDsThree: [],
      stepOneLoader: false,
      stepTwoLoader: false,
      stepThreeLoader: false,
      stepFourLoader: false,
      cashierIDs: null,
      additionalPaymentArray: null,
    }
  },
  mounted() {
    this.loadStepOne();
  },
  computed: {
    headers: function () {
      return [
        {text: this.$t('generic.lang_cashierID'), value: "name", sortable: false},
        {text: this.$t('accounting.costcentre'), value: "value", sortable: false},
        {text: this.$t('accounting.lang_revenueCenter'), value: "value2", sortable: false},
      ];
    }
  },
  methods: {
    onCommitFlagClick() {
      let val = this.stepFourData.disableCommitFlag;

      if(val == 1 || val == true) {
        this.stepFourData.disableCommitFlag = 0;
      } else {
        this.$swal({
          text: this.$t('generic.lang_you_really_want_that'),
          cancelButtonText: this.$t('generic.lang_no'),
          confirmButtonText: this.$t('generic.lang_yes'),
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.stepFourData.disableCommitFlag = 1;
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        });
      }
    },
    saveGroup(id) {
      let data = Object.fromEntries(Object.entries(this.stepOneData).filter(([key]) => key.includes(`waregroupID_${id}`) || key.includes(`_${id}_`)));
      data.itemgroupId = id;
      
      this.savingGroup = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE_ONE_GROUP, data).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.savingGroup = false;
      })

    },
    saveGroupStep2(id) {
      let data = Object.fromEntries(Object.entries(this.stepTwoData).filter(([key]) => {
        if (key.includes(`_cashierID_${id}`) || key.includes(`_kassenid_${id}`)) {
          if (key.endsWith(`_cashierID_${id}`) || key.endsWith(`_kassenid_${id}`)) {
            return key;
          }
        }
      }));
      data.part = "datevGegenkonten";
      data.cashierID = id;
      
      this.savingGroup = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE, data).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.savingGroup = false;
      })
    },
    save() {
      if (this.stepper == 3) {
        this.submitStepThree();
      } else if (this.stepper == 4) {
        this.submitStepFour();
      }
    },
    next() {
      if (this.stepper == 1) {
        this.loadStepTwo();
        this.stepper += 1;
      } else if (this.stepper == 2) {
        this.loadStepThree();
        this.stepper += 1;
      } else if (this.stepper == 3) {
        this.loadStepFour();
        this.stepper += 1;
      } else if (this.stepper == 4) {
        this.stepper = 1;
      }
    },
    previous() {
      if (this.stepper == 1) {
        this.stepper = 1;
      } else if (this.stepper == 2) {
        this.loadStepOne();
        this.stepper -= 1;
      } else if (this.stepper == 3) {
        this.loadStepTwo();
        this.stepper -= 1;
      } else if (this.stepper == 4) {
        this.loadStepThree();
        this.stepper -= 1;
      }
    },
    reset() {
      if (this.stepper == 1) {
        this.loadStepOne();
      } else if (this.stepper == 2) {
        this.loadStepTwo();
      } else if (this.stepper == 3) {
        this.loadStepThree();
      } else if (this.stepper == 4) {
        this.loadStepFour();
      }
    },
    submitStepOne(step) {
      this.stepOneLoader = true;
      this.loading = true;
      this.stepOneData.part = "datevKonten";
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE, {
        part: "datevKonten",
        datevAPISettingsKontoGeldEingangGutscheinVK: this.stepOneData.datevAPISettingsKontoGeldEingangGutscheinVK
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.stepOneLoader = false;
        this.loading = false;
      })
    },
    submitStepTwo(step) {
      this.stepTwoLoader = true;
      this.loading = true;
      this.stepTwoData.part = "datevGegenkonten";
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE, this.stepTwoData).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.stepTwoLoader = false;
        this.loading = false;
      })
    },
    submitStepThree(step) {
      this.stepThreeLoader = true;
      this.loading = true;
      this.stepThreeData.part = "datevKostenstellen";
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE, this.stepThreeData).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.stepThreeLoader = false;
        this.loading = false;
      })
    },
    submitStepFour(step) {
      this.stepFourLoader = true;
      this.loading = true;
      this.stepFourData.part = "datevAPISettings";
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.UPDATE, this.stepFourData).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.stepFourLoader = false;
        this.loading = false;
      })
    },
    loadStepOne() {
      this.waregroupArr = null;
      this.loading = true;
      this.taxArr = null;
      this.stepOneData = {};
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.GET, {
        part: "datevKonten"
      }).then((res) => {
        // ////console.log(res);
        this.waregroupArr = res.data.waregroupArr;
        this.taxArr = res.data.taxArr;
        Object.assign(this.stepOneData, res.data.formFillArray);
        Object.assign(this.stepOneData, res.data.formFillCheckBoxArray);
      }).catch((err) => {
      }).finally(() => {
        this.loading = false;
      })
    },
    loadStepTwo() {
      this.loading = true;
      this.cashierIDs = null;
      this.additionalPaymentArray = null;
      this.stepTwoData = {};
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.GET, {
        part: "datevGegenkonten"
      }).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
        this.additionalPaymentArray = res.data.additionalPaymentArray;

        Object.assign(this.stepTwoData, res.data.formFillPaymentArray);
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    },
    loadStepThree() {
      this.cashierIDsThree = [];
      this.loading = true;
      this.stepThreeData = {};
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.GET, {
        part: "datevKostenstellen"
      }).then((res) => {
        res.data.cashierIDs.forEach((item) => {
          this.cashierIDsThree.push({
            id: item[0],
            name: this.$t('generic.lang_cashierID') + item[1],
          });

          this.stepThreeData[item[0]] = [item[2], item[3]];
        });
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    },
    loadStepFour() {
      this.stepFourData = {};
      this.loading = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.GET, {
        part: "datevAPISettings"
      }).then((res) => {
        this.stepFourData = res.data.formfillData.textFields;
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
